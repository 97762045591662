import React from "react";
import GrowersHeader from "../../../assets/images/logos/underwood/mature-soybeans.png";
import PDF from "../../../assets/images/logos/underwood/pdf.png";
import UnderwoodCommodityCard from "./components/commodityCard";

// Commodity beans
import AdzukiBeans from "../../../assets/images/logos/underwood/beans/adzuki-beans.png";
import NavyBeans from "../../../assets/images/logos/underwood/beans/navy-beans.png";
import BlackBeans from "../../../assets/images/logos/underwood/beans/black-beans.png";
import SmallRedBeans from "../../../assets/images/logos/underwood/beans/red-beans.png";

function Export() {
	return (
		<div
			className="w-full h-full mt-[100px] flex flex-col"
		>
			<div
				className="relative flex flex-col justify-center items-center h-[400px] w-full bg-cover bg-center bg-no-repeat text-white"
				style={{
					backgroundImage: `url(${GrowersHeader})`,
				}}
			>
				<div className="absolute inset-0 bg-black opacity-40"></div>
				<h1 className="relative z-10 text-4xl font-bold text-center mb-3">
					Growers
				</h1>
			</div>
			<div className="w-full h-full p-20">
				<p className="text-2xl font-semibold text-center">
					2025 Edible Bean & IP Soybean Programs:
				</p>
				<div className="flex justify-evenly mt-10">
					<UnderwoodCommodityCard title={"Navy/White"} iconPath={NavyBeans}> </UnderwoodCommodityCard>
					<UnderwoodCommodityCard title={"Black"} iconPath={BlackBeans}> </UnderwoodCommodityCard>
					<UnderwoodCommodityCard title={"Small Red"} iconPath={SmallRedBeans}> </UnderwoodCommodityCard>
					<UnderwoodCommodityCard title={"Adzuki"} iconPath={AdzukiBeans}> </UnderwoodCommodityCard>
				</div>
				<TableComponent />
				<p className="text-2xl mt-5">
					Other market classes & varieties subject to approval.
				</p>
				<p className="text-2xl mt-5">
					Please call for premium pricing and program details.
				</p>
				<p className="text-2xl mt-5">
					In addition to edible bean and IP soybean programs, Underwood Grain also accepts soft red wheat, crush soybeans, and corn. Contact our office to forward-book your 2025 crop.
				</p>
				<p className="text-2xl mt-5">
					Link to GrainView <a className="underline" href="https://www.grainview.ca/">here</a>
				</p>
				<h2 className="text-4xl mt-5 font-bold">
					Documentation
				</h2>
				{/* <div className="flex items-center mt-5">
					<img src={PDF} alt="pdf" className="w-[56px] h-[56px]" />
					<a href="/public/static/ip-list.pdf" download className="text-2xl underline ml-2">Approved pesticide list (IP Soybeans)</a>
				</div>
				<div className="flex items-center mt-5">
					<img src={PDF} alt="pdf" className="w-[56px] h-[56px]" />
					<a href="/public/static/edible-list.pdf" download className="text-2xl underline ml-2">Approved pesticide list (Edible Beans)</a>
				</div> */}
				<div className="flex items-center mt-5">
					<img src={PDF} alt="pdf" className="w-[56px] h-[56px]" />
					<a href="/static/Underwood-Grain-Grower-Checklist.pdf" download className="text-2xl underline ml-2 text-[#2A66B2]">Grower Checklist</a>
				</div>
				<div className="flex items-center mt-5">
					<img src={PDF} alt="pdf" className="w-[56px] h-[56px]" />
					<a href="/static/Underwood-Grain-Producer-Field-Map.pdf" download className="text-2xl underline ml-2 text-[#2A66B2]">Field Map</a>
				</div>
				<div className="flex items-center mt-5">
					<img src={PDF} alt="pdf" className="w-[56px] h-[56px]" />
					<a href="/static/Underwood-Grain-Clean-Transport-Affidavit.pdf" download className="text-2xl underline ml-2 text-[#2A66B2]">Truck Cleanout Affidavit</a>
				</div>
				<div className="flex items-center mt-5">
					<img src={PDF} alt="pdf" className="w-[56px] h-[56px]" />
					<a href="/static/Underwood-Grain-Chemical-Application-List.pdf" download className="text-2xl underline ml-2 text-[#2A66B2]">Pesticide Application List</a>
				</div>
			</div>
		</div>
	);
}

const TableComponent = () => {
	const data = [
		{ variety: 'S03-W4', chu: 2600, hilumColour: 'IY', scnResistance: 60 },
		{ variety: 'S04-K9', chu: 2625, hilumColour: 'Y', scnResistance: 35 },
		{ variety: 'S07-M8', chu: 2725, hilumColour: 'IY', scnResistance: 37 },
		{ variety: 'S12-J7', chu: 2825, hilumColour: 'Y', scnResistance: 26 },
		{ variety: 'OAC Strive', chu: 2650, hilumColour: 'IY', scnResistance: 81 },
	];

	return (
		<div className="flex justify-center mt-10 border border-blue-200">
			<table className="w-full w-7/8">
				<thead>
					<tr className="bg-blue-200">
						<th className="p-3 text-left font-semibold border border-blue-200">Variety</th>
						<th className="p-3 text-left font-semibold border border-blue-200">CHU</th>
						<th className="p-3 text-left font-semibold border border-blue-200">Hilum Colour</th>
						<th className="p-3 text-left font-semibold border border-blue-200">SCN Resistance</th>
					</tr>
				</thead>
				<tbody>
					{data.map((item, index) => (
						<tr key={index}>
							<td className="p-3 border border-blue-200">{item.variety}</td>
							<td className="p-3 border border-blue-200">{item.chu}</td>
							<td className="p-3 border border-blue-200">{item.hilumColour}</td>
							<td className="p-3 border border-blue-200">{item.scnResistance}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default Export;
