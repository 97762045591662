import BusinessLogo from "../../assets/images/logos/underwood/underwood-logo.svg";
import HomepageBackground from "../../assets/images/logos/underwood/combine.jpeg";
import CashBidBackground from "../../assets/images/logos/underwood/tractor.jpg";

const styles = {
	mainColor: "rgba(0,153,102,1.0)",
	backgroundColor: "#FFF",
	btnTextColor: "#2A66B2",
	primaryColor: "#2A66B2",
	primaryVariant: "#D2DDBC",
	secondaryColor: "#2A66B2",
	secondaryVariant: "#CBE2DE",
	surface: "#FFF",
	homeFontColor: "#FFF",
	transparentMainColor: "rgba(0,153,102,0.5)",
	logo: BusinessLogo,
	isLogoSquared: false,
	homepageBackground: HomepageBackground,
	ourStoryImg: CashBidBackground,
};

export default styles;
