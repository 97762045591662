const location = {
  address1: "",
  address2: "Wingham, ON",
  addresses: [
    {
      title: "",
      address1: "41797 Howick-Turnberry Road",
      address2: "Wingham, ON, N0G 2W0",
      coordinate: { lat: 43.91326837900065, lng: -81.22981681117007 },
    },
  ],
  phone: "519-335-3579",
  email: null,
  teamProfile: [],
  coordinate: {
    lat: 43.91326837900065,
    lng: -81.22981681117007,
  },
};

export default location;
