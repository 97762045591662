const aboutUs = {
	title: "Underwood Grain Ltd.",
	paragraphs: [
		"Underwood Grain has been operating since 1977, but our history in the area started long before that.  The Underwood family arrived in Canada from England in 1894 settling in the Waterloo area. As Ontario continued to open up to agriculture the Underwood family moved north to purchase land in Howick township, located in Huron County. George Nelson, our great grandfather who had been a barn framer and carpenter in addition to farming, decided in 1903 to move just west to Turnberry township where the Underwood family purchased the farm immediately across the road from the current grain facility.",
		"From the 1950’s to 70’s, the dynamics of agriculture changed from horse powered equipment and small mixed livestock and crop farms to more specialized agriculture involving larger mechanized farms. Crop specific farms required more storage and handling facilities by the early 1970’s.  As a result, 2 generations after arriving in Canada, George constructed two small bins and a single receiving elevator to handle the crop grown by the Underwood family. George and his two sons, Nelson and John, offered opportunities to a number of area farmers by processing their crops through the same facility.  Over time, the facility has grown in size and complexity to the point where we now serve a large farmer clientele across southwestern Ontario. A close relationship with several other key receiving points allows us to continue that market development.",
		"In 2012, Underwood Grain initially marketed uncleaned non-GMO soybeans to customers in South East Asia. Since 2012, with additional experience and installation of specialized processing equipment, Underwood Grain now processes corn, wheat, edible beans, IP soy, and GMO soybeans to markets worldwide.",
		"Underwood Grain is proud to remain family owned and operated. It is owned by Nelson and John Underwood, the fourth generation descendants. Maitland joined Underwood Grain in 2015 and Matt joined in 2023, becoming the fifth generation to farm in Huron County.",
	],
	landing:
		["We are excited to announce the release of our new site! You can easily view Underwood Grain's cash bids, futures, weather and more."],
	emailList: [
		"tracie@SLGRAINSFS.CA",
	],
	joinUs: false,
	showLogos: true,
	showAddressBanner: true,
	showHomePageContact: true,
	showHomePageMap: false,
};

export default aboutUs;
