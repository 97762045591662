import Hagit from "../../assets/images/StLawrenceGrain/ourTeam/Hagit-Berkovich.png";
import Jason from "../../assets/images/StLawrenceGrain/ourTeam/Jason-Ferguson.png";
import Jillian from "../../assets/images/StLawrenceGrain/ourTeam/Jillian-OConnor.png";
import Lisa from "../../assets/images/StLawrenceGrain/ourTeam/Lisa-Shier.png";
import Robert from "../../assets/images/StLawrenceGrain/ourTeam/Robert-Baird.png";
import Tracie from "../../assets/images/StLawrenceGrain/ourTeam/Tracie-Westington.jpg";

const location = {
  address1: "",
  address2: "Stouffville, ON, Canada",
  addresses: [
    {
      title: "Mailing",
      address1: "PO Box 429, STN Main Uxbridge",
      address2: "ON L9P 1M8",
      coordinate: { lat: 44.10901777624504, lng: -79.12371272349758 },
    },
    {
      title: "Stouffville Grain Elevator, Crop inputs retail / Head Office",
      address1: "3199 York Durham Line 30",
      address2: "Uxbridge, ON, L9P 0C7",
      coordinate: { lat: 44.01257843948114, lng: -79.23755331546471 },
    },
    {
      title: "Havelock Grain Elevator",
      address1: "6830 Highway 7",
      address2: "Havelock, ON, K0L 1Z0",
      coordinate: { lat: 44.437709392262754, lng: -77.82347295963113 },
    },
    {
      title: "Squirrel Creek Grain Elevator",
      address1: "275 Brackenridge Drive, RR#2",
      address2: "Millbrook, ON L0A 1G0",
      coordinate: { lat: 44.126741, lng: -78.392289 },
    },
    {
      title: "Norwood Crop Inputs Distribution",
      address1: "25 11th line north Asphodel Line",
      address2: "Norwood, ON, K0L 2V0",
      coordinate: { lat: 44.393119, lng: -77.907790 },
    }
  ],
  phone: "",
  email: "",
  teamProfile: [
    {
      name: "Hagit Berkovich (Azarov)",
      position: "Business Manager",
      phone: "905-640-2260",
      email: "hagit@slgrainsfs.ca",
      img: Hagit,
    },
    {
      name: "Robert Baird",
      position: "Operations Manager",
      phone: "416-919-8702",
      email: "rbaird@slgrainsfs.ca",
      img: Robert,
    },
    {
      name: "Tracie Westington",
      position: "Grain Merchant",
      phone: "416-919-2838",
      email: "tracie@slgrainsfs.ca",
      img: Tracie,
    },
    {
      name: "Jillian O'Connor",
      position: "Crop Inputs sales & customer relations Manager",
      phone: "905-640-2260",
      email: "jillian@slgrainsfs.ca",
      img: Jillian,
    },
    {
      name: "Jason Ferguson",
      position: "Crop Inputs sales (Peterborough, Norwood and area)",
      phone: "705-768-8453",
      email: "sales@slgrainsfs.ca",
      img: Jason,
    },
    {
      name: "Lisa Shier",
      position: "Crop Inputs Sales & Agronomy",
      phone: "416-919-1565",
      email: "lisa@slgrainsfs.ca",
      img: Lisa,
    },
    {
      name: "Neil Moloney",
      position: "Grain Originator (Peterborough, Norwood, and area)",
      phone: "705-868-6517",
      email: "nmoloney@slgrainsfs.ca",
      img: null,
    },
    {
      name: "Jan Boadway",
      position: "Logistics Admin",
      phone: "905-435-6241",
      email: "logistics@slgrainsfs.ca",
      img: null,
    },
  ],
  coordinate: {
    lat: 44.013149448216545,
    lng: -79.23780007907251,
  },
};

export default location;
