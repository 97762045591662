import React from "react";

const UnderwoodCommodityCard = ({ title, iconPath }) => {
  return (
    <div className="w-[294px] h-[92px]  flex items-center gap-4 bg-[#E1EEFF] rounded-[50px] p-2 shadow-md mr-4 last:mr-0">
      <div className="w-[75px] h-[75px]  rounded-full overflow-hidden flex items-center justify-center bg-white shadow">
        <img src={iconPath} alt={title} className="w-full h-full object-cover" />
      </div>
      <span className="text-lg font-semibold text-left lg:text-xl xl:text-2xl text-gray-800">{title}</span>
    </div>
  );
};

export default UnderwoodCommodityCard;
