import appDetails from "./appDetails";
import { Route } from "react-router-dom";
import Products from "./custom-pages/products";
import AboutUs from "./custom-pages/aboutUsPage";
import Locations from "./custom-pages/locations";

const navItems = {
	nav: [
		{
			title: "Home",
			link: "/home",
			show: true,
		},
		{
			title: "Cash Bids",
			link: "/cashbids",
			show: true,
		},
		{
			title: "Futures",
			link: "/futures",
			show: true,
		},
		{
			title: "Products",
			link: "/products",
			show: true,
		},
		{
			title: "About Us",
			link: "/about-us",
			show: true,
		},
		{
			title: "Locations",
			link: "/locations",
			show: true,
		},
		{
			title: "Weather",
			link: "/weather",
			show: true,
		},
		{
			title: process.env.REACT_APP_BIDS_LOGIN === "true" ? "Bids Login" : "Login",
			link: appDetails.webLink,
			show: true,
		},
	],
	customNavRoute: [
		<Route key={"products"} path="/products" component={Products}></Route>,
		<Route key={"location"} path="/locations" component={Locations}></Route>,
		<Route key={"about us"} path="/about-us" component={AboutUs}></Route>,
	],
};

export default navItems;
