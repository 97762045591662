const appDetails = {
	webLink: "https://underwoodgrain.graindiscovery.com/",
	googlePlayLink: null,
	appStoreLink: null,
	landingPage: "home",
	SignUpButton: false,
	hideFeatures: false,
};

export default appDetails;
