import appDetails from "./appDetails";
import { Route } from "react-router-dom";
import Growers from "./custom-pages/growers";
import Export from "./custom-pages/export";

const navItems = {
	nav: [
		{
			title: "Home",
			link: "/home",
			show: true,
		},
		{
			title: "Cash Bids",
			link: "/cashbids",
			show: true,
		},
		{
			title: "Futures",
			link: "https://www.qtwebsitequotes.com/",
			show: true,
			external: true,
		},
		{
			title: "Export",
			link: "/export",
			show: true,
		},
		{
			title: "Growers",
			link: "/growers",
			show: true,
		},
		{
			title: "Weather",
			link: "/weather",
			show: true,
		},
		{
			title: process.env.REACT_APP_BIDS_LOGIN === "true" ? "Bids Login" : "Login",
			link: appDetails.webLink,
			show: false,
		},
	],
	customNavRoute: [
		<Route key={"export"} path="/export" component={Export}></Route>,
		<Route key={"growers"} path="/growers" component={Growers}></Route>,
	],
};

export default navItems;
