import React from "react";
import stLawrenceGrain from "..";
import { CustomMap } from "../../../components/map/Map";
import Config from "../../config";

function Locations() {
  return (
    <div
      className="w-full pt-40 pb-16 px-16 flex flex-col justify-between"
      style={{ backgroundColor: stLawrenceGrain.styles.backgroundColor }}
    >
      <h1
        className="text-6xl font-bold"
        style={{ color: Config.styles.primaryColor }}
      >
        Locations
      </h1>
      <br />
      <br />
      {Config.location?.addresses?.map((address) => (
        <div className="flex flex-col tablet:flex-row justify-between py-4">
          <div className="flex flex-col items-center justify-center tablet:w-1/4">
            <h3 className="text-center font-bold">{address.title}</h3>
            <br />
            <p className="text-center">{address.address1}</p>
            <p className="text-center">{address.address2}</p>
          </div>
          <br className="tablet:hidden" />
          <div className="flex rounded-3xl overflow-clip tablet:w-3/4">
            <CustomMap coordinate={address.coordinate} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Locations;
