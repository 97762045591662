import React from "react";

import Config from "../../config/config";

import SignUpButton from "../button/SignupButton";
import { navItems } from "../header/Header";
import uuid from "react-uuid";

import iStoreLogo from "../../assets/images/adHoc/iStore.png";
import googleStoreLogo from "../../assets/images/adHoc/googleStore.png";
import GdLogo from "../../assets/images/logos/connected_by_gd.png";

function Footer() {
	const twoRow = navItems
		.filter((item) => {
			return item.show;
		})
		.reduce(function (rows, key, index) {
			return (
				(index % 2 === 0
					? rows.push([key])
					: rows[rows.length - 1].push(key)) && rows
			);
		}, []);

	return (
		<div
			className="w-full py-10 px-8 tablet:py-20 relative items-center tablet:px-16 flex flex-col space-y-4 tablet:space-y-0 tablet:flex-row justify-center tablet:justify-between tablet:items-start"
			style={{ backgroundColor: Config.styles.secondaryColor }}
		>
			<div className="flex flex-col flex-wrap">

				{Config.appDetails.SignUpButton && (
					<>
						<h1 className="text-white text-3xl font-bold pb-3 md:pb-10">
							Sign Up Today
						</h1>

						<SignUpButton
							title="Click Here"
							href="/signup"
							backgroundColor={Config.styles.backgroundColor}
							btnTextColor={Config.styles.btnTextColor}
							borderColor={Config.styles.primaryColor}
						/>
					</>
				)}
			</div>

			<div className="flex-col tablet:flex-row flex tablet:space-x-10">
				<div className="hidden tablet:flex flex-col">
					{twoRow.map((row) => (
						<div
							key={uuid()}
							className="flex text-white text-left "
						>
							{row.map((navMenu) => {
								return (
									<a
										key={navMenu.link}
										className="hover:text-black py-2 w-[180px] duration-300"
										href={navMenu.link}
									>
										{navMenu.title}
									</a>
								);
							})}
						</div>
					))}
				</div>
				{Config.aboutUs.showHomePageContact && (
					<div className="flex flex-col text-white w-[180px]">
						<p className="py-2 font-bold">Let's Chat</p>
						<div className="py-2">
							{typeof Config.location.phone === "string"
								? Config.location.phone
								: Config.location.phone.map((contact, index) => (
									<p key={uuid()}>{contact}</p>
								))}
						</div>
						<div className="py-2">
							{typeof Config.location.email === "string"
								? Config.location.email
								: Config.location.email?.map((contact, index) => (
									<p key={uuid()}>{index % 2 !== 0 && contact}</p>
								))}
						</div>
					</div>
				)}
			</div>
			<div className="flex flex-col items-start space-y-4">
				{Config.appDetails.SignUpButton && (
					<>
						<a href={Config.appDetails.appStoreLink}>
							<img
								className="h-12 w-40 object-fill"
								src={iStoreLogo}
								alt="Appstore Icon"
							/>
						</a>
						<a href={Config.appDetails.googlePlayLink}>
							<img
								className="h-12 w-40"
								src={googleStoreLogo}
								alt="Google Store Icon"
							/>
						</a>
					</>
				)}
			</div>
			<div className="tablet:absolute tablet:bottom-5 tablet:left-1/2 tablet:transform tablet:-translate-x-1/2">
				<img
					className="h-10"
					src={GdLogo}
					alt="Connected By Grain Discovery"
				></img>
			</div>
		</div>
	);
}

export default Footer;
